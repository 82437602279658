.footer-container {
  display: flex;
  flex-direction: column;
}

.website-credits {
  font-size: 1rem;
  font-weight: normal;
  color: var(--mainWhite);
  z-index: 1;
  margin-top: 1rem;
  opacity: 0.5;
}

.view-source {
  margin-bottom: 1rem;
}

.view-source>a {
  font-size: 1rem;
  font-weight: normal;
  color: var(--mainWhite);
  z-index: 1;
  text-decoration: none;
}

.view-source>svg {
  margin-right: 0.25rem;
  width: 16px;
  height: 16px;
  stroke: var(--mainWhite)
}