:root {
  --timeline-width: 8vw;
  --timeline-content-width: 30vw;
}

.timeline-container {
  /* max-width: 1100px; */
  padding-left: 15px;
  padding-right: 15px;
  margin: 0 auto;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  /* align-items: center; */
  position: relative;
  width: 100%;
}

.timeline {
  list-style: none;
  position: relative;
  margin: 30px 0;
}

.timeline .timeline-segment {
  width: calc(var(--timeline-width));
  left: calc(var(--timeline-width) / -2);
  position: absolute;
  border-color: var(--mainWhite);
}

.timeline .timeline-segment:nth-child(odd) {
  border-style: solid;
  border-width: 2px 2px 2px 0;
  transform: translateX(50%);
  border-radius: 0 80px 80px 0;
  /* box-shadow: 13px 0 15px -12px rgba(0, 0, 0, .3); */
}

.timeline .timeline-segment:nth-child(even) {
  border-style: solid;
  border-width: 2px 0 2px 2px;
  transform: translateX(-50%);
  border-radius: 80px 0 0 80px;
  /* box-shadow: -15px 0 15px -12px rgba(0, 0, 0, .5); */
}

/* Style pseudo Element */
.timeline .timeline-segment:before {
  content: '';
  position: absolute;
  height: 18px;
  width: 18px;
  border-radius: 50%;
  top: 50%;
  transform: translateY(-50%);
  background-color: var(--mainWhite);
  color: var(--darkBackground);
}

.timeline .timeline-segment:nth-child(odd):before {
  right: 0;
  transform: translateY(-50%) translateX(50%);
}

.timeline .timeline-segment:nth-child(even):before {
  left: 0;
  transform: translateY(-50%) translateX(-50%);
}

.timeline .timeline-segment:nth-child(1):after,
.timeline .timeline-segment:last-child:after {
  content: '';
  position: absolute;
  height: 10px;
  width: 10px;
  border-radius: 50%;
  background-color: var(--mainWhite);
}

.timeline .timeline-segment:nth-child(1):after {
  top: 0;
  transform: translate(-50%, -50%)
}

.timeline .timeline-segment:last-child:after {
  bottom: 0;
  right: 0;
  transform: translate(50%, 50%)
}

/* Style content */
.timeline .content {
  /* border: 1px solid var(--mainWhite); */
  position: absolute;
  width: fit-content;
  min-width: var(--timeline-content-width);
  top: 50%;
  transform: translateY(-50%);
  /* transition: all 0.2s ease; */
  transition: all 500ms cubic-bezier(0.230, 1.000, 0.320, 1.000);
  -webkit-transition: all 500ms cubic-bezier(0.230, 1.000, 0.320, 1.000);
}

.timeline .content:hover {
  transform: translateY(-50%) scale(1.025);
}

.timeline .timeline-segment:nth-child(odd) .content {
  left: calc(100% + 30px);
}

.timeline .timeline-segment:nth-child(even) .content {
  right: calc(100% + 30px);
}

.timeline .content>.content-inner {
  position: relative;
  display: flex;
  padding: 1rem 1.5rem;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.timeline .company-info-container {
  width: 100%;
}

.timeline .timeline-segment:nth-child(odd) .company-info-container {
  flex-direction: row-reverse;
}

.timeline .company-logo-container {
  border-radius: 50%;
  display: flex;
  width: fit-content;
  margin-right: 18px;
}

.timeline .timeline-segment:nth-child(odd) .company-logo-container {
  margin-right: 0;
  margin-left: 18px;
}

.timeline .company-logo-container>img {
  width: 50px;
  height: 50px;
  object-fit: contain;
  filter: brightness(0) invert(1);
}

.timeline .company-text-info {
  align-items: flex-end;
  width: 100%;
}

.timeline .company-text-info>h3,
.timeline .company-text-info>h4,
.timeline .company-text-info>p {
  text-align: end;
  width: 100%;
}

.timeline .timeline-segment:nth-child(odd) .company-text-info>h3,
.timeline .timeline-segment:nth-child(odd) .company-text-info>h4,
.timeline .timeline-segment:nth-child(odd) .company-text-info>p {
  text-align: start;
}

.timeline .company-name {
  font-size: 1.5rem;
  font-weight: normal;
  color: var(--mainWhite);
  margin-top: 0.25rem;
}

.timeline .job-position {
  font-size: 1rem;
  font-weight: normal;
  color: var(--mainWhite);
  margin-top: 0.25rem;
}

.timeline .description {
  font-size: 1rem;
  font-weight: 300;
  color: var(--mainWhite);
  margin-top: 1.5rem;
  width: 100%;
  text-align: start;
  font-family: 'Degular', sans-serif;
}

.timeline .description.bulleted {
  padding-left: 1rem !important;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin-top: 1rem;
}


.timeline .job-dates {
  font-size: 0.9rem;
  font-weight: normal;
  color: var(--mainWhite);
  margin-top: 0.5rem;
  opacity: 0.8;
  /* font-style: italic; */
}

.timeline .skills-container {
  flex-wrap: wrap;
  justify-content: flex-end;
  align-items: center;
  margin-top: 1rem;
  width: 100%;
}

.timeline .timeline-segment:nth-child(odd) .skills-container {
  justify-content: flex-start;
}

.skills-container>.skill {
  margin: 0.25rem;
  padding: 0.25rem 0.5rem;
  border: 1px solid var(--mainWhite);
  color: var(--mainWhite);
  font-size: 0.9rem;
  font-weight: 200;
  font-family: 'Degular', sans-serif;
  /* opacity: 0.8; */
}

@media screen and (max-width: 1230px) {
  .timeline-container {
    margin-top: calc(10rem - 10vw);
  }
}

.mobile-timeline-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: fit-content;
}

.mobile.timeline {
  position: relative;
  width: 100%;
  margin: 1rem 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  height: fit-content;
}

.mobile.timeline .mobile.content {
  position: relative;
  top: auto;
  transform: translateY(0);
  margin: 0;
  width: 70vw;
  height: fit-content;
}

.mobile-content-segment-container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  position: relative;
  margin: 1rem 0;
  padding-left: 2rem;
}

.mobile-timeline-segment-line {
  width: 2px;
  height: calc(100% + 2rem);
  background-color: var(--mainWhite);
  margin-right: 1rem;
  z-index: 1;
  position: absolute;
  left: 0;
  top: -1rem;
  /* transform: translateX(-50%); */
}

.mobile-content-segment-container:first-child>.mobile-timeline-segment-line,
.mobile-content-segment-container:last-child>.mobile-timeline-segment-line {
  height: calc(100% + 1rem) !important;
}

.mobile-content-segment-container:first-child>.mobile-timeline-segment-line {
  top: 0 !important;
}

.mobile-timeline-segment-dot {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: var(--mainWhite);
  position: absolute;
  top: 50%;
  left: -4px;
  z-index: 2;
}

@media screen and (max-width: 500px) {

  .mobile-content-segment-container .description {
    font-size: 0.9rem !important;
  }

  .skills-container>.skill {
    font-size: 0.75rem;
  }
}