.modal-background {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 3 !important;
  /* transition: all 0.25s ease; */
  transition: all 300ms cubic-bezier(0.445, 0.050, 0.550, 0.950);
  -webkit-transition: all 300ms cubic-bezier(0.445, 0.050, 0.550, 0.950);
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
  opacity: 0;
  border: none !important;
  border-radius: 0px;
  background: rgba(250, 250, 250, 0.025) !important;
}

.modal-background.active {
  opacity: 1;
  pointer-events: all;
}

.modal-content {
  background-color: var(--darkBackground);
  padding: 25px;
  position: relative;
  min-width: 250px;
  max-width: 600px;
  width: 40vw;
  max-height: calc(100vh - 100px);
  overflow-y: auto;
  z-index: 5;
  transform: translateY(-100%);
  transition: all 300ms cubic-bezier(0.445, 0.050, 0.550, 0.950);
  -webkit-transition: all 300ms cubic-bezier(0.445, 0.050, 0.550, 0.950);
  border-radius: 6px;
  /* border: 1px solid var(--mainWhite); */
  /* background: rgba(250, 250, 250, 0.2) !important; */
}

.modal-content-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.modal-background.active>.modal-content {
  transform: translateY(0);
}


.modal-close-icon {
  position: absolute;
  top: 5px;
  right: 5px;
  padding: 6px;
  fill: var(--mainWhite);
  width: 36px;
  height: 36px;
  cursor: pointer;
  transition: all 0.25s ease;
  pointer-events: all;
}

.modal-close-icon:hover {
  transform: scale(1.1);
}

.modal-content h1 {
  margin-bottom: 24px;
  font-size: 24px;
  color: var(--mainWhite);
}

@media only screen and (max-width: 650px) {
  .modal-content {
    width: 75%;
    max-width: 75%;
    min-width: none;
  }
}