.contact-section {
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 1;
  padding-top: calc(1.5*var(--navbarHeight));
}

.contact-section-title {
  font-size: 2.5rem;
  font-weight: normal;
  color: var(--mainWhite);
  margin: 0 2rem;
  margin-top: 1rem;
  margin-bottom: 2.5rem;
  z-index: 1;
  text-align: center;
}

.contact-section-title>i {
  font-family: 'Monarcha', sans-serif;
}

.contact-section-content {
  width: fit-content;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  z-index: 1;
  padding: 2rem;
  margin-bottom: 4rem;
}

.csc-content-container:first-child {
  margin-right: 3rem;
}

.csc-content-container>.navbar__socials-container {
  margin: 0 !important;
}

.csc-description {
  font-size: 1.2rem;
  font-weight: normal;
  color: var(--mainWhite);
  z-index: 1;
  margin-bottom: 0.75rem;
}

.csc-email {
  display: flex;
  align-items: center;
  justify-content: center;
}

.csc-email>svg {
  margin-right: 0.5rem;
  width: 26px;
  height: 26px;
  stroke: var(--mainWhite)
}

.csc-email>a {
  font-size: 1.5rem;
  font-weight: normal;
  color: var(--mainWhite);
  z-index: 1;
  text-decoration: none;
}

@media screen and (max-width: 650px) {
  .contact-section-title {
    font-size: 1.75rem;
    margin-top: 0.5rem;
  }

  .contact-section-content {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 80vw;
    padding: 1.5rem;
  }

  .csc-content-container:first-child {
    margin-right: 0;
    margin-bottom: 1.5rem;
  }

  .csc-description {
    font-size: 1.1rem;
  }

  .csc-email {
    margin-top: -0.5rem;
  }

  .csc-email>a {
    font-size: 1.25rem;
  }

  .csc-email>svg {
    width: 20px;
    height: 20px;
    margin-right: 0.4rem;
  }

  .csc-content-container>.navbar__socials-container {
    margin: 0;
  }
}

@media screen and (max-width: 450px) {
  .contact-section-title {
    font-size: 1.5rem;
  }

  .contact-section-content {
    width: 90vw;
    padding: 1rem;
  }

  .csc-email>a {
    font-size: 1.25rem;
  }

  .csc-email>svg {
    width: 16px;
    height: 16px;
    margin-right: 0.3rem;
  }

}