.mobile-menu {
  display: none;
  position: relative;
  margin: 24px;
}

.mobile-menu-btn>svg {
  width: 24px;
  height: 24px;
  stroke: var(--mainWhite);
}

.hamburger {
  width: 32px;
  height: 32px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;
  transition: all ease 0.8s;
  padding: 0.5rem 0.35rem;
}

.hamburger>span {
  width: 100%;
  height: 1.5px;
  background-color: var(--mainWhite);
  transform-origin: left;
  transition: all 500ms cubic-bezier(0.230, 1.000, 0.320, 1.000);
  -webkit-transition: all 500ms cubic-bezier(0.230, 1.000, 0.320, 1.000);
}

.hamburger.hamburger {
  padding: 0.48rem 0.35rem;
}

.hamburger.active>span:nth-child(1) {
  transform: rotate(45deg) translate(2.15px, -2.15px);
}

.hamburger.active>span:nth-child(2) {
  opacity: 0;
}

.hamburger.active>span:nth-child(3) {
  transform: rotate(-45deg) translate(2.15px, 2.15px);
}

@media screen and (max-width: 1080px) {
  .mobile-menu {
    display: block;
  }
}

.mobile-menu-content {
  opacity: 0;
  max-height: 0;
  position: absolute;
  top: 48px;
  right: 0;
  padding: 1rem 2rem;
  width: calc(100vw - 48px);
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  pointer-events: none;
  transition: all 1s cubic-bezier(0.230, 1.000, 0.320, 1.000);
  -webkit-transition: all 1s cubic-bezier(0.230, 1.000, 0.320, 1.000);
  transition-delay: 250ms;
  overflow: hidden;
}

.mobile-menu-content>* {
  margin: 0.5rem 0;
  font-size: 1.25rem;
  font-weight: normal;
  cursor: pointer;
  width: 100%;
  text-align: left;
  justify-content: start;
}

.mobile-menu-content.active {
  opacity: 1;
  pointer-events: all;
  max-height: 50vh;
  transition-delay: 0s;
  transition: all 1.5s cubic-bezier(0.230, 1.000, 0.320, 1.000);
  -webkit-transition: all 1.5s cubic-bezier(0.230, 1.000, 0.320, 1.000);
}

.divider {
  width: 0%;
  height: 1px;
  background-color: var(--mainWhite);
  margin: 0;
  animation: shrink 500ms cubic-bezier(0.230, 1.000, 0.320, 1.000) forwards;
  -webkit-animation: shrink 500ms cubic-bezier(0.230, 1.000, 0.320, 1.000) forwards;
}

.mobile-menu-content.active>.divider {
  animation: expand 500ms cubic-bezier(0.230, 1.000, 0.320, 1.000) forwards;
  -webkit-animation: expand 500ms cubic-bezier(0.230, 1.000, 0.320, 1.000) forwards;
  animation-delay: 300ms;
}

@keyframes expand {
  from {
    width: 0%;
  }

  to {
    width: 100%;
  }
}

@-webkit-keyframes expand {
  from {
    width: 0%;
  }

  to {
    width: 100%;
  }
}

@keyframes shrink {
  from {
    width: 100%;
  }

  to {
    width: 0%;
  }
}

@-webkit-keyframes shrink {
  from {
    width: 100%;
  }

  to {
    width: 0%;
  }
}

.mobile-menu-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--darkBackground);
  z-index: 0;
  opacity: 0;
  pointer-events: none;
}

.mobile-menu-overlay.active {
  pointer-events: all;
}