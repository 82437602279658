.experience-section {
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 1;
  padding-top: calc(1.5*var(--navbarHeight));
  /* ===== temp ===== */
  min-height: 100vh;
  /* border-top: 1px solid white; */
  /* ===== temp ===== */
}

.experience-section-title {
  font-size: 2.5rem;
  font-weight: normal;
  color: var(--mainWhite);
  margin: 1rem 2rem;
  z-index: 1;
  text-align: center;
}

@media screen and (max-width: 650px) {
  .experience-section-title {
    font-size: 2rem;
  }
}