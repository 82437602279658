.project-modal-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 6px;
  margin-bottom: 1rem;
}

.project-modal-title {
  font-size: 2.5rem;
  color: var(--mainWhite);
  width: fit-content;
  height: fit-content;
  margin-bottom: 0 !important;
  margin-right: 1rem;
}

.project-modal-description {
  font-size: 1.1rem;
  margin-bottom: 1rem;
  color: var(--mainWhite);
}

.project-modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.5rem;
}

.pm.cc__instant-link-btn {
  width: fit-content;
}

.pm.skills-container {
  justify-content: flex-start;
  flex-wrap: wrap;
}

@media screen and (max-width: 500px) {
  .project-modal-description {
    font-size: 1rem;
  }

  .pm.cc__instant-link-btn {
    padding: 0.3rem 0.7rem;
    font-size: 0.9rem;
  }

  .project-modal-header>.project-modal-title {
    margin: 0;
    margin-bottom: 1rem !important;
  }

  .project-modal-header {
    flex-direction: column;
    align-items: flex-start;
  }
}